import { memo } from 'react';
import { css } from '@emotion/react';
import { fill_horizontal_cross_center, fill_vertical_cross_center, } from '~/modules/AppLayout/FlexGridCss';
// 將YoutubeChannelSwitchButton單一職責化
// 接收"頻道是否被選中"、"點擊頻道後的行為"參數並執行
export const YoutubeChannelSwitchButton = memo(function YoutubeChannelSwitchButton({ isSelected, onClick, children }) {
    // if (typeof youtubeVideoID === 'undefined') return null
    return (<span css={css `
        ${classes.column.youtubeChannelName};
        border-bottom: 1.6px solid ${isSelected ? '#ff0000' : 'transparent'};
        font-size: ${isSelected ? '12.6' : '12.4'}px;
        color: ${isSelected ? '#ff0000' : 'black'};
      `} onClick={() => onClick()}>
      {children}
    </span>);
});
const classes = {
    column: {
        container: css `
      ${fill_vertical_cross_center};
      gap: 4px;
      padding: 2px;
      background-color: #ffffff;
      border-radius: 4px;
    `,
        youtubeChannelName: css `
      ${fill_horizontal_cross_center};
      height: 24px;
      font-weight: 500;
      cursor: pointer;
      padding: 0 2px;
      &:hover {
        border-bottom: 1.6px solid #787878;
      }
      transition: 0.3s;
    `,
    },
};
