import styled from '@emotion/styled';
import { memo } from 'react';
import { fill_horizontal_cross_center, fill_vertical_all_center, flex, } from '~/modules/AppLayout/FlexGridCss';
import useMedia from '~/hooks/useMedia';
import { scrollbar2Css } from '~/css/scrollbarCss';
export const ProductContent = memo(function ProductContent() {
    const { isPhone } = useMedia();
    return (<styleds.container isPhone={isPhone}>
      <styleds.header>
        <styleds.image src='asiajye_promotion/獨家策略.png'/>
        <styleds.link>
          <a href='https://docs.google.com/forms/d/1zxvZiGIMXeiZ9BI1tFVxxgu7_wtV391Gk01fjMWLBM8/viewform?edit_requested=true'>
            <styleds.loadMore>點我看更多...</styleds.loadMore>
          </a>
        </styleds.link>
      </styleds.header>
      <styleds.body>
        <styleds.productImage src='asiajye_promotion/asiajye-stock.png'/>
      </styleds.body>
    </styleds.container>);
});
const styleds = {
    container: styled.div `
    ${scrollbar2Css};
    ${fill_vertical_all_center};
    border-radius: 4px;
    padding: 0 8px;
    background-color: #fff;
    box-shadow: 0 2px 4px 0 #c5cbce;

    /* 手機版版位寬度為100vw，使其比例為1：2且不會因為高度過小而產生滑桿*/
    max-height: ${prop => (prop.isPhone ? '200px' : '')};
    /* 避免寬度太少，導致內容被壓縮 */
    min-width: 250px;
    /* 避免因排版而看不到內容的保險設定 */
    overflow: auto;
    /* 在手機版面與上方的區塊有間隔
    margin-top: 8px; */
  `,
    header: styled.div `
    ${fill_vertical_all_center};
    /* 在手機版面與上方的區塊有間隔
    margin-top: 8px; */
    height: 32px;
    padding: 0 8px;
    border-radius: 4px;
    background-color: #eeeeee;
    font-weight: 500;
    /* 固定表頭 */
    position: sticky;
    top: -9px;
  `,
    image: styled.img `
    max-height: 64px;
    border-radius: 4px;
  `,
    link: styled.div `
    position: absolute;
    top: 50%;
    /* 讓"點我看更多..."(約80px)可以出現在更好的位置 */
    left: calc(100% - 80px);
    transform: translate(0, -50%);
  `,
    body: styled.div `
    background-color: #fafafa;
    max-height: 90%;
    width: 100%;
    ${flex.v.crossCenter};
  `,
    productImage: styled.img `
    /* 設定長度讓滾動條不要出現 */
    max-height: 80%;
    border-radius: 4px;
    margin: auto;
  `,
    productIntroduce: styled.div `
    ${flex.v.crossCenter};
    padding-left: 16px;
    margin: auto;
    gap: 4px;
  `,
    introduceTitle: styled.div `
    ${fill_horizontal_cross_center};
    font-weight: 600;
  `,
    introduceItem: styled.div `
    ${fill_horizontal_cross_center};
  `,
    loadMore: styled.div `
    font-size: 12px;
    color: #676767;
    cursor: pointer;
  `,
    iconProduct: styled.img `
    max-width: 36px;
    padding: 0 4px;
    margin-left: -4px;
  `,
};
