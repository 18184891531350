import { memo } from 'react';
import useMedia from '~/hooks/useMedia';
import { AnalystInfoContent } from '~/pages/asiajye_promotion/component/AnalystInfoContent/AnalystInfoContent';
import { ProductContent } from '~/pages/asiajye_promotion/component/ProductContent/ProductContent';
import { YoutubeVideoPlayList } from '~/pages/asiajye_promotion/component/YoutubeVideoPlayList/YoutubeVideoPlayList';
import { AnalystStockPerformance } from '~/pages/asiajye_promotion/component/AnalystStockPerformance/AnalystStockPerformance';
import styled from '@emotion/styled';
export const AsiajyePromotionPcLayout = memo(function AsiajyePromotionPcLayout() {
    const { isPhone } = useMedia();
    if (isPhone)
        return null;
    return (<styleds.container>
      <styleds.area1>
        <AnalystInfoContent />
        <AnalystStockPerformance />
      </styleds.area1>

      <styleds.area2>
        <ProductContent />
        <YoutubeVideoPlayList />
      </styleds.area2>
    </styleds.container>);
});
const styleds = {
    container: styled.div `
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 100vh;
    grid-template-areas: 'area1 area2';
  `,
    area1: styled.div `
    grid-area: area1;
    height: 100%;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 550px 1fr;
  `,
    area2: styled.div `
    grid-area: area2;
    height: 100%;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 500px 1fr;
  `,
};
