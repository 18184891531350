import { memo } from 'react';
import useMedia from '~/hooks/useMedia';
import { Preset_Topbar } from '~/pages/heineken_template/_preset/preset_Topbar';
export const AsiajyePromotionMobileTopbar = memo(function AsiajyePromotionMobileTopbar() {
    const { isPhone } = useMedia();
    // 如果不是手機版就不顯示
    if (!isPhone)
        return null;
    return <Preset_Topbar showLeftBurger/>;
});
