import { memo } from 'react';
import useMedia from '~/hooks/useMedia';
import { css } from '@emotion/react';
import { pageStore } from '~/pages/asiajye_promotion/pageStore';
import { useSnapshot } from 'valtio';
import { YoutubeVideoPlayList } from '~/pages/asiajye_promotion/component/YoutubeVideoPlayList/YoutubeVideoPlayList';
import { AnalystInfoContent } from '~/pages/asiajye_promotion/component/AnalystInfoContent/AnalystInfoContent';
import { ProductContent } from '~/pages/asiajye_promotion/component/ProductContent/ProductContent';
import { AnalystStockPerformance } from '~/pages/asiajye_promotion/component/AnalystStockPerformance/AnalystStockPerformance';
import { fill_vertical_cross_center, flex } from '~/modules/AppLayout/FlexGridCss';
export const AsiajyePromotionMobile = memo(function AsiajyePromotionMobile() {
    const state = useSnapshot(pageStore);
    const selectedMoblieSection = state.selectedMoblieSection;
    const { isPhone } = useMedia();
    if (!isPhone)
        return null;
    if (selectedMoblieSection === 'marketUpdates') {
        return (<div css={css `
          /* 讓子元件可以吃到高度設定 */
          height: 100%;
          ${fill_vertical_cross_center};
        `}>
        <YoutubeVideoPlayList />
        <AnalystStockPerformance />
      </div>);
    }
    else if (selectedMoblieSection === 'analystInfoContent') {
        return (<div css={css `
          /* 讓子元件可以吃到高度設定 */
          height: 100%;
          ${flex.v.crossCenter};
        `}>
        <AnalystInfoContent />
        <ProductContent />
      </div>);
    }
});
