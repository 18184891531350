import { useSnapshot } from 'valtio';
import { css } from '@emotion/react';
import { memo, useEffect } from 'react';
import { pageStore } from '~/pages/asiajye_promotion/pageStore';
import YouTube from 'react-youtube';
import { YoutubeVideoPlayListSidePane } from '~/pages/asiajye_promotion/component/YoutubeVideoPlayList/Component/PlayListSidePane/YoutubeVideoPlayListSidePane';
import { fill_horizontal_all_center, fill_horizontal_cross_center, fill_vertical_cross_center, fill_vertical_all_center, } from '~/modules/AppLayout/FlexGridCss';
import { scrollbar2Css } from '~/css/scrollbarCss';
import styled from '@emotion/styled';
import useMedia from '~/hooks/useMedia';
import { useGetYoutubePlaylistData } from '~/pages/asiajye_promotion/function/useGetYoutubePlaylist';
const opts = {
    playerVars: {
        autoplay: 0,
    },
};
export const playList = [
    {
        youtubeVideoPlayerType: 'player',
        youtubeChannelName: 'player',
        youtubeChannelChineseName: '影片播放器',
    },
    {
        youtubeVideoPlayerType: 'list',
        youtubeChannelName: 'asiajyeStock',
        videoId: null,
        youtubeChannelChineseName: '股市杰帥',
    },
    {
        youtubeVideoPlayerType: 'list',
        youtubeChannelName: 'asiajyeFuture',
        videoId: null,
        youtubeChannelChineseName: '功夫期貨',
    },
    {
        youtubeVideoPlayerType: 'list',
        youtubeChannelName: 'stockInvestorsForum',
        videoId: null,
        youtubeChannelChineseName: '股民開講',
    },
    {
        youtubeVideoPlayerType: 'player',
        youtubeChannelName: 'ebc',
        videoId: 'LbS-xQ67fos',
        youtubeChannelChineseName: '東森財經直播',
    },
];
export const YoutubeVideoPlayList = memo(function VideoContent() {
    const state = useSnapshot(pageStore);
    const { isPhone } = useMedia();
    // const asiajyeVideoDatas = useGetYoutubeData('UCApPy1JadvPVPpTe5H0vKHg').data
    const asiajyeStockVideoPlaylistDatas = useGetYoutubePlaylistData('PLbPTU1vohB_zrtfRpjDTCSF9YrI_U-A-k').data;
    const asiajyeFutureVideoPlaylistDatas = useGetYoutubePlaylistData('PLbPTU1vohB_yxo6opGxqHlkQN5bQs59m8').data;
    const stockInvestorsForumPlaylistDatas = useGetYoutubePlaylistData('PL9mUJWHev0KlrCNSHwxclpc5wEZwJyDCq').data;
    // const asiajyeVideo = asiajyeVideoDatas?.data.map(s => s.attributes)
    const asiajyeStockVideoPlaylist = asiajyeStockVideoPlaylistDatas?.data.map(s => s.attributes);
    const asiajyeFutureVideoPlaylist = asiajyeFutureVideoPlaylistDatas?.data.map(s => s.attributes);
    const stockInvestorsForumPlaylist = stockInvestorsForumPlaylistDatas?.data.map(s => s.attributes);
    const dataOfChannelType = () => {
        switch (state.selectedYoutubeChannelName) {
            // case 'asiajye':
            //   return asiajyeVideo?.flatMap(item => item.entry)
            case 'stockInvestorsForum':
                return stockInvestorsForumPlaylist?.flatMap(item => item.entry);
            case 'asiajyeStock':
                return asiajyeStockVideoPlaylist?.flatMap(item => item.entry);
            case 'asiajyeFuture':
                return asiajyeFutureVideoPlaylist?.flatMap(item => item.entry);
            default:
                return asiajyeStockVideoPlaylist?.flatMap(item => item.entry);
        }
    };
    //預設的影片id
    const defaultYoutubeVideoID = asiajyeStockVideoPlaylist?.[0].entry?.[0].video_id ?? '';
    useEffect(() => {
        if (defaultYoutubeVideoID === '') {
            pageStore.selectedYoutubeVideoId = defaultYoutubeVideoID;
        }
    }, [defaultYoutubeVideoID]);
    return (<styleds.container isPhone={isPhone}>
      <div css={classes.header}>
        <img src={'asiajye_promotion/今日市場動態.png'} css={classes.image}/>
      </div>
      <div css={classes.playlistContainer}>
        {/* 左側控制欄 */}
        <div css={classes.column.container}>
          <img src={'./freeman/yt_logo_rgb_dark.png'} css={classes.youtubeIcon}/>
          <YoutubeVideoPlayListSidePane playList={playList} stateStore={pageStore}/>
        </div>
        {/* 播放器 */}
        {state.youtubeVideoPlayerType === 'player' && (<div css={classes.videoPlayer.container}>
            <YouTube videoId={state.selectedYoutubeVideoId} opts={opts}/>
          </div>)}

        {/* 播放清單 */}
        {state.youtubeVideoPlayerType === 'list' && (<div css={classes.videoList.container}>
            {dataOfChannelType()?.map(video => (<div css={classes.card.container} onClick={() => {
                    pageStore.selectedYoutubeVideoId = video.video_id;
                    pageStore.youtubeVideoPlayerType = 'player';
                    pageStore.selectedYoutubeChannelName = 'player';
                }} key={video.video_id}>
                <img css={classes.card.images} src={video.media_thumbnail_url}/>
                <span css={classes.card.titleContainer}>
                  <span css={classes.card.title}> {video.title}</span>
                </span>
              </div>))}
          </div>)}
      </div>
    </styleds.container>);
});
const styleds = {
    container: styled.div `
    width: 100%;
    /* min-height: 500px; */
    overflow: auto;
    border-radius: 4px;
    border-radius: 4px;
    padding: 8px;
    gap: 4px;
    background-color: #fff;
    box-shadow: 0 2px 4px 0 #c5cbce;
    /* 限制在手機版的長度 */
    max-height: ${prop => (prop.isPhone ? '50vh' : '')};
  `,
};
const classes = {
    container: css `
    width: 100%;
    overflow: auto;
    border-radius: 4px;
    border-radius: 4px;
    padding: 8px;
    gap: 4px;
    background-color: #fff;
    box-shadow: 0 2px 4px 0 #c5cbce;
  `,
    playlistContainer: css `
    display: grid;
    //切出側邊欄和影片清單
    grid-template-columns: 108px 1fr;
    width: 100%;
    /* 當切換到影片播放器時，滾動條不會跳出來，但是影片清單會跳出來，所以不能設定為100％ */
    height: 90%;
    overflow: auto;
  `,
    youtubeIcon: css `
    width: 90px;
    padding: 6px 0;
    margin-bottom: 6px;
  `,
    header: css `
    ${fill_vertical_all_center};
    height: 32px;
    padding: 0 8px;
    border-radius: 4px;
    background-color: #eeeeee;
    font-weight: 500;
    /* 固定表頭 */
    position: sticky;
    top: -9px;
  `,
    image: css `
    max-height: 64px;
    border-radius: 4px;
  `,
    column: {
        container: css `
      ${fill_vertical_cross_center};
      gap: 4px;
      padding: 2px;
      background-color: #ffffff;
      border-radius: 4px;
    `,
        youtubeChannelName: css `
      ${fill_horizontal_cross_center};
      height: 24px;
      font-weight: 500;
      cursor: pointer;
      padding: 0 2px;
      &:hover {
        border-bottom: 1.6px solid #787878;
      }
      transition: 0.3s;
    `,
    },
    videoPlayer: {
        container: css `
      ${scrollbar2Css};
      // 讓影片撐滿整個容器，不留空白

      width: 100%;
      height: 100%;
      & > * {
        width: 100%;
        height: 100%;
      }
      & > * > iframe {
        // 讓影片撐滿整個容器，不留空白
        width: 100%;
        height: 100%;
      }
    `,
    },
    videoList: {
        container: css `
      ${fill_vertical_cross_center};
      ${scrollbar2Css};
      gap: 6px;
      padding: 2px;
    `,
    },
    card: {
        container: css `
      ${fill_horizontal_all_center};
      display: grid;
      grid-template-columns: 100px 1fr;
      width: 100%;
      max-height: 72px;
      border-radius: 4px;
      flex-shrink: 0;
      cursor: pointer;
      background-color: #fafafa;
      &:hover {
        background-color: #787878;
      }
    `,
        images: css `
      width: 100%;
      height: 100%;
      max-height: 72px;
    `,
        titleContainer: css `
      ${fill_vertical_cross_center};
      max-height: 60px;
    `,
        title: css `
      width: 100%;
      height: auto;
      font-size: 13px;
      line-height: 16px;
      font-weight: 500;
      color: #212020;
      padding: 0 2px;
      overflow: hidden;
    `,
        description: css `
      width: 100%;
      height: 100%;
      font-size: 12px;
      line-height: 14px;
      color: #212020;
      white-space: pre-wrap;
      overflow: hidden;
      padding: 0 2px;
      transform: scale(0.94);
      margin-left: -6%;
    `,
        date: css `
      ${fill_vertical_cross_center};
      align-items: start;
      font-size: 12px;
      line-height: 16px;
      font-weight: 400;
      color: #212020;
      & > * {
        border-radius: 6px;
        background-color: #252525;
        transform: scale(0.8);
        padding: 1px 4px;
      }
    `,
    },
};
