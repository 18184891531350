import { memo } from 'react';
import { YoutubeChannelSwitchButton } from './Component/YoutubeChannelSwitchButton';
import { pageStore } from '~/pages/asiajye_promotion/pageStore';
import { playList } from '~/pages/asiajye_promotion/component/YoutubeVideoPlayList/YoutubeVideoPlayList';
import { useSnapshot } from 'valtio';
export const YoutubeVideoPlayListSidePane = memo(function PlayListSidePane({ stateStore }) {
    const state = useSnapshot(stateStore);
    return playList.map((item, index) => (<YoutubeChannelSwitchButton key={index} isSelected={state.selectedYoutubeChannelName === item.youtubeChannelName} onClick={() => {
            if (typeof item.videoId === 'string')
                pageStore.selectedYoutubeVideoId = item.videoId;
            pageStore.youtubeVideoPlayerType = item.youtubeVideoPlayerType;
            pageStore.selectedYoutubeChannelName = item.youtubeChannelName;
        }}>
      {item.youtubeChannelChineseName}
    </YoutubeChannelSwitchButton>));
});
