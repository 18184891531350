export function createUnorderedList(text) {
    const splitText = text.split('\n');
    if (splitText.length === 1)
        return text;
    // 使用 map 函數而非 reduce，以簡化代碼並提高可讀性
    const listItems = splitText.map((line, index) => {
        if (index === splitText.length - 1)
            return;
        return <li key={index}>{line}</li>;
    });
    return <ul>{listItems}</ul>;
}
export function createLineBreak(text) {
    if (typeof text !== 'string')
        return text;
    const splitText = text.split('\n');
    if (splitText.length === 1)
        return text;
    const listItems = splitText.map((line, index) => {
        return <div key={index}>{line}</div>;
    });
    return listItems;
}
export function removeCommas(text) {
    if (typeof text !== 'string')
        return text;
    return text.replace(/,|、/g, '\n');
}
export function removeChineseSpace(text) {
    if (typeof text !== 'string')
        return text;
    return text.replace(/ /g, '\n');
}
