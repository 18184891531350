import { memo } from 'react';
import useMedia from '~/hooks/useMedia';
import { css } from '@emotion/react';
import { pageStore } from '~/pages/asiajye_promotion/pageStore';
import styled from '@emotion/styled';
import { flex } from '~/modules/AppLayout/FlexGridCss';
const section = [
    // {
    //   //預計為主站的一些小功能為網頁加值，例如：訊號牆
    //   title: ' 盤中即時資訊',
    //   sectionName: 'instantStockMarketInfo',
    //   imgLocation: 'asiajye_promotion/instantStockMarketInfo.png',
    // },
    {
        // 曾英杰簡介、社群文章、還有聯絡資訊
        title: '關於我們',
        sectionName: 'analystInfoContent',
        imgLocation: 'asiajye_promotion/analystInfoContent.png',
    },
    {
        // 曾英杰簡介、社群文章、還有聯絡資訊
        title: '今日股市動態',
        sectionName: 'marketUpdates',
        imgLocation: 'asiajye_promotion/marketUpdates.png',
    },
    // {
    //   // yt影片
    //   title: '今日股市動態',
    //   sectionName: 'marketUpdates',
    // },
];
export const AsiajyePromotionMobileSidePane = memo(function AsiajyePromotionMobileSidePane() {
    const { isPhone } = useMedia();
    // 如果不是手機版就不顯示
    if (!isPhone)
        return null;
    return (<div css={css `
          position: fixed;
          width: 50%;
          height: 100%;
          overflow: hidden;
          z-index: 999;
          background-color: #787878;
          border: #ffffff 1px solid;
        `}>
        {section.map((item, index) => {
            return (<div key={index} css={css `
                width: 100%;
                height: 7%;
                font-size: 20px;
                line-height: 40px;
                color: #ffffff;
                border: #ffffff 1px solid;
                padding-left: 10px;
                overflow: hidden;
                ${flex.h.crossCenter}
              `} onClick={() => {
                    pageStore.selectedMoblieSection = item.sectionName;
                }}>
              <styleds.image src={item.imgLocation}/>
            </div>);
        })}
        <styleds.coverImage src='asiajye_promotion/cover.png'/>
      </div>);
});
const styleds = {
    image: styled.img `
    max-height: 50%;
    border-radius: 4px;
  `,
    coverImage: styled.img `
    max-width: 100%;
    border-radius: 4px;
    position: absolute;
    bottom: 0;
  `,
};
