import { css } from '@emotion/react';
import { useThemeStore } from '~/components/theme/useThemeStore';
import { createIPadCss } from '~/css/createIPadCss';
import { createMobileCss2 } from '~/css/createMobileCss';
import { store } from '~/pages/heineken_template/_private/store';
import { AsiajyePromotionMobileTopbar } from '~/pages/asiajye_promotion/component/Mobile/AsiajyePromotionMobileTopbar';
import { AsiajyePromotionMobileSidePane } from '~/pages/asiajye_promotion/component/Mobile/AsiajyePromotionMobileSidePane';
import { AsiajyePromotionMobile } from '~/pages/asiajye_promotion/component/Mobile/AsiajyePromotionMobile';
import { SloganImage } from '~/pages/asiajye_promotion/component/SloganImage/SloganImage';
import { AsiajyePromotionPcLayout } from '~/pages/asiajye_promotion/component/PcLayout/AsiajyePromotionPcLayout';
import { AsiajyePromotionFooter } from '~/pages/asiajye_promotion/component/Footer/AsiajyePromotionFooter';
export const asiajyePromotion_init = {
    indexPage(templateProps) {
        useThemeStore.setState({ theme: 'light' });
        store.charting.setThemeMode(useThemeStore.getState().theme);
        //templateProps.permissions.pageview = undefined
        templateProps.layout.login = undefined;
        templateProps.layout.cssset = css `
      grid-template-rows: 10% auto 1fr;
      grid-template-columns: 0px 1fr 1fr;
      place-content: flex-start;
      background-color: #f2f2f2;

      /* 設定側欄位彈出時的寬度為零 */
      /* 因為Preset_Topbar的.Drawer1的背景會超過'AsiajyePromotionMobileTopbar'的寬度，所以要設定為0 */
      .Drawer1 {
        width: 0%;
      }

      grid-template-areas:
        'Drawer1 Row1 Row1'
        'Drawer1 Row3 Row3'
        'Row6 Row6 Row6';

      ${createIPadCss(css `
        grid-template-rows: 10% auto 1fr;
        grid-template-columns: 0px 1fr 1fr;
        grid-template-areas:
          'Drawer1 Row1 Row1'
          'Drawer1 Row3 Row3'
          'Row6 Row6 Row6';
      `)}
      ${createMobileCss2(css `
        grid-template-rows: 48px 64px 1fr 64px;
        grid-template-columns: 1fr 1fr 1fr;
        grid-template-areas:
          'Row4 Row4 Row4'
          'Row1 Row1 Row1'
          'Row5 Row5 Row5'
          'Row6 Row6 Row6';
      `)};
    `;
        // 為了在手機板上，讓「SidePane」可以在「Topbar」menu裡面顯示
        // 在電腦版Drawer1 就先設定SidePane，但不顯示
        templateProps.layout.Drawer1 = AsiajyePromotionMobileSidePane;
        templateProps.layout.Charting = undefined;
        templateProps.layout.Row1 = SloganImage;
        templateProps.layout.Row3 = AsiajyePromotionPcLayout;
        templateProps.layout.Row4 = AsiajyePromotionMobileTopbar;
        templateProps.layout.Row5 = AsiajyePromotionMobile;
        templateProps.layout.Row6 = AsiajyePromotionFooter;
    },
};
