import { memo } from 'react';
import styled from '@emotion/styled';
import { fill_vertical_all_center } from '~/modules/AppLayout/FlexGridCss';
export const SloganImage = memo(function SloganImage() {
    return (<styleds.container>
      <styleds.image src='asiajye_promotion/sloganImage.png'/>
    </styleds.container>);
});
const styleds = {
    container: styled.div `
    ${fill_vertical_all_center};
    border-radius: 4px;
    padding: 8px;
    gap: 4px;

    height: 100%;
  `,
    image: styled.img `
    height: 100%;
    border-radius: 4px;
  `,
};
