import useSWR from 'swr';
export const useGetYoutubePlaylistData = (playlistId) => {
    const apiUrl = 'https://cms.futures-op.com/api/youtube-videos?filters%5Bplaylist_id%5D%5B$eq%5D=' + playlistId;
    const res = useSWR(apiUrl, errorEmptyArrayFetcher, {
        shouldRetryOnError: false,
    });
    return {
        data: res.data,
    };
};
const errorEmptyArrayFetcher = async (url) => {
    const res = await fetch(url);
    return !res.ok ? [] : res.json();
};
