import styled from '@emotion/styled';
import { memo } from 'react';
import { flex } from '~/modules/AppLayout/FlexGridCss';
import { css } from '@emotion/react';
export const AsiajyePromotionFooter = memo(function AsiajyePromotionFooter() {
    return (<styleds.container>
      <span css={css `
          text-align: center;
        `}>
        版權所有© 亞洲證券投資顧問股份有限公司 All rights reserved.
      </span>
      <span css={css `
          text-align: center;
        `}>
        本網站資料係由亞洲證券投資顧問股份有限公司提供，僅供參考，投資時應審慎評估，過往之績效不代表未來獲利之保證。
      </span>
    </styleds.container>);
});
const styleds = {
    container: styled.div `
    ${flex.v.allCenter};
    background-color: #f0f0f0;
    border-top: 1px solid #cccccc;
    color: #252525;
    /* max-height: 10%; */
    font-size: 14px;
    & > * {
      padding: 4px;
    }
  `,
};
