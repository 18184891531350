import { memo, useState } from 'react';
import { useStockPerformance } from '~/pages/asiajye_promotion/function/useStockForumData';
import styled from '@emotion/styled';
import { fill_vertical_all_center, fill_vertical_cross_center, flex, fill, } from '~/modules/AppLayout/FlexGridCss';
import { createLineBreak, } from '~/pages/asiajye_promotion/function/createTextMarkup';
import { css } from '@emotion/react';
import useMedia from '~/hooks/useMedia';
import { scrollbar2Css } from '~/css/scrollbarCss';
export const AnalystStockPerformance = memo(function AnalystStockPerformance() {
    const useStockPerformanceData = useStockPerformance()?.data;
    const [isSelected, setIsSelected] = useState(0);
    const { isPhone, isPc } = useMedia();
    if (typeof useStockPerformanceData === 'undefined')
        return null;
    return (<styleds.container isPhone={isPhone}>
      <styleds.header>
        <styleds.image src='asiajye_promotion/AnalystStockPerformance.png'/>
      </styleds.header>
      <styleds.body>
        {useStockPerformanceData.map((item, index) => {
            return (<styleds.contentContainer isSelected={isSelected === index} key={index} onClick={() => {
                    setIsSelected(index);
                }}>
              <styleds.contentTitle>
                <div>{item.lastUpdated} 大探極</div>
              </styleds.contentTitle>
              {/* 如果是pc端的話，顯示大圖 */}
              {isSelected === index && isPc && (<styleds.contentPicture>
                  <img src={item.small}/>
                </styleds.contentPicture>)}
              {isSelected === index && !isPc && (<styleds.contentPicture>
                  <img src={item.thumbnail}/>
                </styleds.contentPicture>)}
              {isSelected === index && (<styleds.contentText>
                  <styleds.contentArticle>{createLineBreak(item.content)}</styleds.contentArticle>
                </styleds.contentText>)}
            </styleds.contentContainer>);
        })}
      </styleds.body>
    </styleds.container>);
});
const styleds = {
    container: styled.div `
    border-radius: 4px;
    padding: 8px;
    /* gap: 12px; */
    background-color: #fff;
    box-shadow: 0 2px 4px 0 #c5cbce;
    /* 避免因排版而看不到內容的保險設定 */
    overflow: auto;
    /* 在手機版面與上方的區塊有間隔 */
    /* margin-top: 8px; */
    width: 100%;
    /* 限制在手機版的長度 */
    max-height: ${prop => (prop.isPhone ? '50vh' : '')};
    ${scrollbar2Css};
  `,
    header: styled.div `
    ${fill_vertical_all_center};
    /* 在手機版面與上方的區塊有間隔
    margin-top: 8px; */
    height: 32px;
    padding: 0 8px;
    border-radius: 4px;
    background-color: #eeeeee;
    font-weight: 500;
    /* 固定表頭 */
    position: sticky;
    top: -9px;
  `,
    image: styled.img `
    max-height: 64px;
    border-radius: 4px;
  `,
    body: styled.div `
    ${fill_vertical_cross_center};
    padding: 8px;
  `,
    contentContainer: styled.div `
    border: 1px solid #e5e5e5;
    background-color: #fafafa;
    border-radius: 4px;
    padding: 8px 16px;
    margin: 8px;
    width: 100%;

    ${props => props.isSelected &&
        css `
        display: grid;
        grid-template-columns: 60% 40%;
        grid-template-rows: 10% 90%;
        grid-template-areas:
          'header header'
          'picture text';
        gap: 4px;
      `}
  `,
    contentTitle: styled.div `
    grid-area: header;
    margin: auto;
    ${fill}
    > div {
      ${flex.v.mainCenter}
      font-size: 24px;
      font-weight: 700;
      /* margin: 8px; */
      /* 以下為裝飾線設定 */
      width: 95%;
      height: 80%;
      padding-bottom: 10px;
      border-bottom: 1px solid #8a8787;
    }
  `,
    contentPicture: styled.div `
    grid-area: picture;
    ${flex.h.allCenter}
    height: 100%;
    > img {
      /* width: 30vw; */
      /* width: 100%; */
      max-height: 90%;
      float: none;
      image-rendering: -moz-crisp-edges; /* Firefox */
      image-rendering: -o-crisp-edges; /* Opera */
      image-rendering: -webkit-optimize-contrast; /*Webkit (non-standard naming) */
      image-rendering: crisp-edges;
      -ms-interpolation-mode: nearest-neighbor; /* IE (non-standard property) */
      /* object-position: center center; */
      object-fit: contain;
    }
  `,
    contentText: styled.div `
    grid-area: text;
    ${fill_vertical_all_center}
    padding: 8px;
    /* margin-left: 5px; */
  `,
    contentArticle: styled.div `
    width: 100%;
    > div {
      width: 100%;
    }
  `,
};
